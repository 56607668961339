import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  fontWeight: '600',
  borderRadius: 'full',
  transition: 'background-color .2s, color .2s',
  border: 'solid 1px',
  borderColor: 'brand.100',
  bg: 'brand.100',
  color: 'white',
  fontFamily: "'Marianne', Arial, sans-serif",
  fontSize: '1em',
  lineHeight: '1.777em',
  px: 'revert',
  pl: '1.333em',
  pr: '1.333em',
  pt: '1em',
  pb: '1em',
  whiteSpace: 'wrap',
  _hover: {
    bg: 'white',
    color: 'brand.100',
  },
});

// TO DO : renommer toutes les variantes "à la française", afin de correspondre au mieux aux noms du design système de l'Institut Français

const danger = defineStyle({
  borderColor: 'danger.100',
  color: 'white',
  bg: 'danger.100',
  _hover: {
    bg: 'white',
    color: 'danger.100',
  },
});

const success = defineStyle({
  borderColor: 'success.100',
  color: 'white',
  bg: 'success.100',
  _hover: {
    bg: 'white',
    color: 'success.100',
  },
});

const outline = defineStyle({
  bg: 'white',
  borderColor: 'brand.100',
  color: 'brand.100',
  _hover: {
    bg: 'brand.100',
    color: 'white',
  },
});

const ghost = defineStyle({
  borderColor: 'transparent',
  color: 'inherit',
  bg: 'white',
  _hover: {
    color: 'inherit',
    bg: 'gray.25',
  },
});

const whiteOutline = defineStyle({
  bg: 'white',
  color: 'black',
  _hover: {
    bg: 'brand.100',
    borderColor: 'white',
    color: 'white',
  },
});

const link = defineStyle({
  border: 'none',
  bg: 'transparent',
  color: 'inherit',
  textDecoration: 'underline',
  fontSize: '1em',
  fontWeight: 'inherit',
  _hover: {
    bg: 'none',
    color: 'inherit',
    textDecoration: 'none',
  },
});

const black = defineStyle({
  bg: 'black',
  borderColor: 'black',
  _hover: {
    bg: 'white',
    color: 'black',
  },
});

const tile = defineStyle({
  bg: 'tuile.100',
  borderColor: 'tuile.100',
  color: 'black',
  _hover: {
    color: 'black',
    bg: 'white',
  },
});

const tuile = defineStyle({
  bg: 'tuile.100',
  borderColor: 'tuile.100',
  color: 'black',
  _hover: {
    color: 'black',
    bg: 'white',
  },
});

const tuileOutline = defineStyle({
  bg: 'white',
  borderColor: 'tuile.100',
  color: 'black',
  _hover: {
    bg: 'tuile.100',
    borderColor: 'white',
    color: 'black',
  },
});

const tileOutline = defineStyle({
  bg: 'white',
  borderColor: 'tuile.100',
  color: 'black',
  _hover: {
    bg: 'tuile.100',
    borderColor: 'white',
    color: 'black',
  },
});

const tileOutline2 = defineStyle({
  bg: 'tuile.100',
  borderColor: 'black',
  color: 'black',
  _hover: {
    bg: 'white',
  },
});

const tuile50 = defineStyle({
  bg: 'tuile.50',
  borderColor: 'tuile.50',
  color: 'black',
  _hover: {
    color: 'black',
    bg: 'white',
  },
});

const sunflower = defineStyle({
  bg: 'tournesol.100',
  borderColor: 'tournesol.100',
  color: 'black',
  _hover: {
    color: 'black',
    bg: 'white',
  },
});

const tournesol = defineStyle({
  bg: 'tournesol.100',
  borderColor: 'tournesol.100',
  color: 'black',
  _hover: {
    color: 'black',
    bg: 'white',
  },
});

const tournesol50 = defineStyle({
  bg: 'tournesol.50',
  borderColor: 'tournesol.50',
  color: 'black',
  _hover: {
    color: 'black',
    bg: 'white',
  },
});

const mint = defineStyle({
  bg: 'menthe.100',
  borderColor: 'menthe.100',
  color: 'black',
  _hover: {
    color: 'black',
    bg: 'white',
  },
});

const menthe50 = defineStyle({
  bg: 'menthe.50',
  borderColor: 'menthe.50',
  color: 'black',
  _hover: {
    color: 'black',
    bg: 'white',
  },
});

const mintOutline = defineStyle({
  bg: 'white',
  borderColor: 'menthe.100',
  color: 'black',
  _hover: {
    bg: 'menthe.100',
    borderColor: 'white',
    color: 'black',
  },
});

const sunflowerOutline = defineStyle({
  bg: 'white',
  borderColor: 'tournesol.100',
  color: 'black',
  _hover: {
    bg: 'tournesol.100',
    borderColor: 'white',
    color: 'black',
  },
});

const tournesolOutline = defineStyle({
  bg: 'white',
  borderColor: 'tournesol.100',
  color: 'black',
  _hover: {
    bg: 'tournesol.100',
    borderColor: 'white',
    color: 'black',
  },
});

const mintRevert = defineStyle({
  bg: 'white',
  borderColor: 'menthe.100',
  color: 'black',
  _hover: {
    color: 'black',
    bg: 'menthe.100',
  },
});

const lightblue = defineStyle({
  bg: 'brand.25',
  borderColor: 'brand.25',
  color: 'black',
  _hover: {
    color: 'black',
    bg: 'white',
  },
});

const lighttile = defineStyle({
  bg: 'tuile.50',
  borderColor: 'tuile.50',
  color: 'black',
  _hover: {
    color: 'black',
    bg: 'white',
  },
});

const lightsunflower = defineStyle({
  bg: 'tournesol.50',
  borderColor: 'tournesol.50',
  color: 'black',
  _hover: {
    color: 'black',
    bg: 'white',
  },
});

const lightmint = defineStyle({
  bg: 'menthe.50',
  borderColor: 'menthe.50',
  color: 'black',
  _hover: {
    color: 'black',
    bg: 'white',
  },
});

const fakeSelect = defineStyle({
  backgroundColor: 'white',
  cursor: 'pointer',
  color: 'black',
  width: '100%',
  border: '1px solid',
  borderColor: 'black.50',
  borderRadius: 'full',
  fontWeight: 'normal',
  fontSize: '1rem',
  pl: '1em',
  pr: '2em',
  py: '.25rem',
  outlineOffset: '-1px !important',
  position: 'relative',
  _focus: {
    outlineColor: 'brand.100',
  },
  _hover: {
    borderColor: 'black.50',
    color: 'black',
  },
  _invalid: {
    borderColor: 'danger.100',
    color: 'danger.100',
  },
});

const cumulus = defineStyle({
  bg: 'cumulus.100',
  borderColor: 'cumulus.100',
  color: 'black',
  _hover: {
    color: 'black',
    bg: 'white',
  },
});

const xxs = defineStyle({
  fontSize: '0.75rem',
  minHeight: '0',
  pl: '.5rem',
  pr: '.5rem',
  pt: '.125rem',
  pb: '.125rem',
  lineHeight: '1',
  h: '1.5rem',
  maxHeight: 'auto',
});
const xs = defineStyle({
  fontSize: '0.875rem',
  pl: '.5rem',
  pr: '.5rem',
  pt: '.25rem',
  pb: '.375rem',
  minHeight: '2.25rem',
});

const sm = defineStyle({
  fontSize: '0.875rem',
  pl: '1.25rem',
  pr: '1.25rem',
  py: '1.4375rem',
});

const md = defineStyle({
  fontSize: '1.125em',
  h: 'revert',
  pt: '.875rem',
  pb: '1rem',
});

const lg = defineStyle({
  fontSize: '1.5rem',
  pt: '2.5rem',
  pb: '2.5rem',
});

export const buttonTheme = defineStyleConfig({
  baseStyle,
  variants: {
    danger,
    success,
    outline,
    whiteOutline,
    link,
    ghost,
    black,
    tile,
    tuile,
    tuileOutline,
    tuile50,
    tileOutline,
    tileOutline2,
    sunflower,
    tournesol,
    tournesol50,
    mint,
    menthe50,
    mintRevert,
    mintOutline,
    sunflowerOutline,
    tournesolOutline,
    lightblue,
    lighttile,
    lightsunflower,
    lightmint,
    fakeSelect,
    cumulus,
  },
  sizes: {
    xxs,
    xs,
    sm,
    md,
    lg,
  },
  defaultProps: {
    size: 'md',
    variant: null,
  },
});
